.contactBox {
  width: 150px;
  height: 150px;
  background-color: #f3f4f6;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
#logo,
.contactBoxLogo {
  width: 50px !important;
  color: #3085c3 !important;
}
.contactBoxText {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  max-width: 120px;
  white-space: normal;
}
.contactBoxLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#logo {
  padding: 8px;
}
.orta {
  margin-top: auto;
  margin-bottom: auto;
}
.contactBoxTextArea {
  display: inline-block;
  word-break: break-word;
  table-layout: fixed;
}

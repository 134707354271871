.servicebox .image-container {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}

.servicebox .image-container:hover .card-img-top {
  transform: scale(1.2);
}

.card {
  margin: 30px;
  border: none;
}
.card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.projeBox {
  cursor: pointer;
  background-color: #fff0;
}
.card.projeBox {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.projectbox {
  margin: 15px;
  width: 20vw;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.play-icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.image-container:hover .play-icon-overlay {
  opacity: 1;
}

.modal-body {
  padding: 0;
}
.card-text {
  font-size: 15px;
}
.image-container {
  overflow: hidden;
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}
.modal-dialog {
  width: 400px;
}
.image-container:hover .card-img-top {
  transform: scale(1.2);
}
@media (max-width: 768px) {
  .projectbox {
    width: 350px;
    height: 250px;
  }
  .modal-dialog {
    margin-top: 150px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .card {
    margin: 0 !important;
    border: none;
  }
  .projeBox {
    width: 250px;
    margin-left: 0 !important;
  }
}

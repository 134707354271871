.footerImg {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 50%;
  margin-left: 15%;
}
.footerContact {
  font-size: 15px;
  color: #fff;
  align-items: center;
  justify-content: center;
}
.footerRow {
  justify-content: space-around;
}
.footerBottom {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .footerContact {
    padding: 20px;
    padding-top: 0;
  }
}

.generalDiv{
    height: 600px;
    scroll-margin: 20px;
}
.video {
    object-fit: cover;
    opacity: 0.7 black;
    background-color: black; 
}

.title{
    color: #fff;
    font-size: 65px;
    font-weight: 700;
    opacity: 1.2;
} 
.generalDiv {
    position: relative;
    width: 100%;
    height: 85vh;
    overflow: hidden;
}

.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    color: #fff;
    font-weight: 700;
    z-index: 2;
    text-align: center;
    width: 100%;
}

.typingText {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 55px;
    color: #fff;
    font-weight: 700;
    z-index: 2;
    text-align: center;
    width: 100%;
}

@media (max-width: 768px) {
    .typingText
    {font-size:25px !important;}
    .title{
        font-size:30px;
   }
}
